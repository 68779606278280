@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

.switch {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  padding: 5px;
  background: rgba(0, 0, 0, 0.6);
  border-bottom-left-radius: 17px;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: height 0.3s ease, width 0.3s ease, background-color 0.3s ease;
  user-select: none;
  cursor: pointer;

  // Відміняє синю підсвітку на iOS та Android
  -webkit-tap-highlight-color: transparent;

  &--expand {
    width: 145px;
    height: 100px;
  }

  &__flag {
    width: 35px;
    height: 20px;
    overflow: hidden;
  }

  &__flag-img {
    width: 40px;
    height: auto;
    object-fit: cover;
    filter: invert(1);
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 1;
  }

  &__flag-img1 {
    height: 25px;
  }

  &__menu-flag {
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 7px;
    margin: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    &:hover {
      transform: scale(1.04);
    }
  }

  &__btn2 {
    background-color: $theme-color;
    color: white;
    border-radius: 7px;
    padding: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  &__row {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 5px;
    padding: 0 5px;
  }

  p {
    font-size: 14px;
    color: white;
    margin: 0;
  }
}
