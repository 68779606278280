@use "./variables.scss" as *;

body::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(black, 0.7);
}
body::-webkit-scrollbar-thumb {
  background-color: $theme-color;
  border-radius: 5px;
}
body::-webkit-scrollbar-track {
  background-color: rgba($theme-color, 0.1);
}
