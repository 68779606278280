.footer {
  // background-color: #1c1c1c;
  background: linear-gradient(135deg, #1c1c1c, #333333);
  color: #f1f1f1;
  padding-top: 20px;
  font-family: "Montserrat", sans-serif;

  &__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__col {
    flex: 1;
    min-width: 250px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: #ffffff;
    text-shadow: 2px 2px 3px rgba(#ffffff, 0.4);
  }

  &__text {
    margin-bottom: 10px;
    color: #d1d1d1;
  }

  &__link {
    color: #d1d1d1;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #ffffff;
      text-decoration: underline;
    }
  }

  &__socials {
    display: flex;
    gap: 15px;
    margin-top: 10px;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  &__social-link {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, filter 0.3s ease;
    // border: 1px solid transparent;
    &:hover {
      filter: invert(1);
      transform: scale(1.1);
      background-color: black;
      border-radius: 50%;
    }

    img {
      width: 100%; // зображення займає весь контейнер
      height: auto; // зберігає пропорції
      filter: invert(0.8);
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      transparent,
      white,
      transparent
    );
    margin: 10px 0;
  }

  &__bottom {
    margin-top: 10px;
    text-align: center;
  }

  &__copy {
    font-size: 0.875em;
    color: #a1a1a1;
  }
}
