@use "../style/normalize.scss" as *;
@use "../style/variables.scss" as *;
@use "./mixin.scss" as mixin;

.app {
  position: relative;
  flex: 1;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  font-family: "Poppins", serif;
  // height: 100%;
  // margin: 0 auto;
  // gap: 30px;

  // Відміняє синю підсвітку на iOS та Android
  -webkit-tap-highlight-color: transparent;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.spaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.spinner {
  @include mixin.spinner;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.flag {
  max-width: 25px;
  max-height: 20px;
  margin-top: 6px;
}
