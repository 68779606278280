@use "../../../style/variables.scss" as *;
@use "../../../style/mixin.scss" as mixin;

.projectCard {
  width: 300px;
  height: 480px;
  perspective: 1000px;
  cursor: pointer;
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-5px);
  }

  &__inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  &--flipped .projectCard__inner {
    transform: rotateY(180deg);
  }

  &__frontSide,
  &__backSide {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

  &__frontSide {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: linear-gradient(
      to top left,
      lighten($theme-color, 50%),
      lighten($theme-color, 70%)
    );
  }

  &__backSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    transform: rotateY(180deg);
    padding: 15px;
    background: lighten($theme-color, 45%);
  }

  &__image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    width: 100%;
    margin: 0;
    font-size: 1.2em;
    color: #333;
    text-align: center;
    @include mixin.articleTitle;
  }

  &__description {
    margin: 0 5px;
    color: #303030;
    font-size: 15px;
  }

  &__tech {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__techImg {
    width: auto;
    height: 40px;
    object-fit: contain;
    border-radius: 4px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__btn,
  &__url,
  &__url2 {
    width: 100%;
    padding: 5px 0px;
    color: $theme-color;
    text-align: center;
    border: none;
    border-radius: 4px;
    background: transparent;
    text-decoration: none;
    letter-spacing: 1px;
    font-family: "Roboto", serif;
    cursor: pointer;
  }

  &__btn--active,
  &__url--active {
    background: linear-gradient(to left, transparent, #007bff, transparent);
    color: white;
  }

  &__url {
    font-size: 17px;
  }

  &__url2 {
    font-size: 12px;
  }
}
