@use "../../../style/variables.scss" as *;
@use "../../../style/mixin.scss" as mixin;

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: "Poppins", sans-serif;
  min-height: 110vh;
  overflow: hidden;
  padding: 100px 0;

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 10vh; // висота хвилі
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120"><path fill="%23ffffff" d="M0,90 C480,30 960,120 1440,120L1440,120L0,120Z"></path></svg>')
      no-repeat bottom;
    background-size: cover;
    z-index: 1;
    // @media (max-width: 600px) {
    //   height: 10vh; // висота хвилі для мобільних пристроїв
    // }
  }

  @media (max-width: 900px) {
    min-height: 600px;
    .slider__image {
      object-position: 35% center;
    }
  }
  @media (max-width: 700px) {
    min-height: 400px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: brightness(0.8); // затемнення
    @media (max-width: 600px) {
      object-position: 75% center;
    } // ВИРІВНЮВАННЯ ВИДИМОЇ ЗОНИ
  }

  &__image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); // затемнення
      z-index: 0;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 800px;
    height: 450px;
    padding: 30px;
    font-family: "Montserrat", serif;
    letter-spacing: 2px;

    // background-color: rgba(black, 0.2);
    // box-shadow: 0 0 20px rgba(black, 0.2);

    // background-color: rgba(black, 0.2);
    // box-shadow: 0 0 15px rgba(black, 0.2);

    border-radius: 20px;
    z-index: 1;
    @media (max-width: 600px) {
      padding: 10px;
    }
  }

  &__title-text,
  &__title-text2 {
    display: block;
    // color: lighten($theme-color, 15%);
    font-family: "IBM Plex Serif", serif;
    color: white;
    font-size: 75px;
    font-weight: bold;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 3px 3px 7px rgba(73, 73, 73, 0.8);
    @media (max-width: 800px) {
      font-size: 60px;
    }
    @media (max-width: 600px) {
      font-size: 40px;
    }
    @media (max-width: 500px) {
      font-size: 28px;
    }
  }

  &__title-text {
    font-size: 75px;

    @media (max-width: 800px) {
      font-size: 60px;
    }
    @media (max-width: 600px) {
      font-size: 40px;
    }
    @media (max-width: 500px) {
      font-size: 28px;
    }
  }

  &__title-text2 {
    font-size: 50px;

    @media (max-width: 800px) {
      font-size: 40px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
    }
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  &__title-btn {
    max-width: 200px;
    width: 100%;
    margin: 0 20px;
    height: 50px;
    font-size: 20px;
    color: white;
    background: transparent;
    border-radius: 7px;
    border: 3px solid $theme-color;
    cursor: pointer;
    transition: background 0.4s ease;
    &:hover {
      background: $theme-color;
    }
    &:active {
      background: darken($theme-color, 10%);
      border: 3px solid darken($theme-color, 10%);
    }
  }

  &__arrows {
    position: absolute;
    width: 100px;
    height: 100%;
    color: $theme-color;
    cursor: pointer;
    z-index: 1;
    transition: transform 0.5s ease;
    &:hover {
      transform: scale(1.2);
    }
    &--right {
      right: 10px;
    }
    &--left {
      left: 10px;
    }
  }

  &__slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    min-width: 100%;
    // filter: blur(3px) brightness(0.7);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
    }
    &--active {
      opacity: 1;
    }
  }

  &__spinner {
    @include mixin.spinner;
  }
}

.dots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  position: absolute;
  bottom: 10%;
  left: 50%;

  transform: translateX(-50%);
  width: 75%;
  z-index: 1;
  &__dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid lighten($theme-color, 69%);
    margin: 5px 5px;
    background: lighten($theme-color, 69%);
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.3);
    }
    &--active {
      background-color: $theme-color;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
      transform: scale(1.2);
    }
  }
}
