@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: relative;
  // &__bg {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100vh;
  //   background-position: top center;
  //   background-repeat: no-repeat;
  //   background-attachment: fixed;
  //   object-fit: cover;
  //   z-index: -1;
  // }
  &__img {
    width: 350px;
    height: auto;
    // object-fit: contain;
  }
  &__message {
    max-width: 400px;
    margin: 10px;
    font-size: 30px;
    font-weight: bold;
    color: $theme-color;
    &--big {
      text-transform: uppercase;
      font-size: 35px;
      @include mixin.articleTitle;
    }
  }
  &__btn {
    @include mixin.button;
    // padding: 10px 20px;
    // font-size: 18px;
    // background-color: $theme-color;
    // color: white;
    // border-radius: 10px;
    // margin-top: 20px;
    // cursor: pointer;
  }
}
