// варіант 1
/* Базовий стиль для SVG */
.particles-flow {
  display: block;
  width: 100%;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  // @media (max-width: 600px) {
  //   display: none;
  // }
  &--normal {
    height: 80px;
  }
  &--short {
    height: 55px;
  }
}

// варіант 2
.particles-grid {
  width: 100%;
  height: 100%;
}
