@use "../../../style/variables.scss" as *;
@use "../../../style/mixin.scss" as mixin;

.tabGrid {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Poppins", sans-serif;
  min-height: 70vh;
  overflow: hidden;
  padding: 0 10px;

  &__flagLine,
  &__nav {
    margin-left: 5%;
    @media (max-width: 600px) {
      margin-left: unset;
    }
  }

  &__flagLine {
    position: absolute;
    top: 37px;
    height: 40px;
    z-index: 2;
    @media (max-width: 425px) {
      top: 50px;
      left: -10px;
      height: 30px;
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-start;
    padding-left: 40px;
    @media (max-width: 470px) {
      padding-left: unset;
    }
    // border-bottom: 2px solid #eaeaea;
  }

  &__tab {
    position: relative;
    padding: 10px 15px;
    margin: 0 5px;
    background-color: lighten($theme-color, 50%);
    // border-radius: 5px 5px 0 0;
    // border: 1px solid #eaeaea;
    color: #555;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);

    &:hover {
      cursor: pointer;
      background-color: lighten($theme-color, 15%);
      // color: #000;
      // transform: translateY(-2px);
    }

    &:first-of-type {
      border-top-left-radius: 20px;
    }

    &:last-of-type {
      border-top-right-radius: 20px;
    }
  }

  &__tab--active {
    background-color: lighten($theme-color, 15%);
    border-bottom: none;
    color: white;
    // box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    // box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

  &__layout {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 25px;
    background-color: #fff;
    // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 5px 5px;
    gap: 15px;
  }

  &__img {
    margin: 15px;
    height: 250px;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.4);
    object-fit: cover;
    object-position: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.5);
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
}

.projectGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // padding: 20px;
  gap: 30px;
}
