@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

.headers {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  pointer-events: none; // КЛІКИ ЗАБОРОНЕНО
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  transition: height 0.5s ease-in-out;
  padding: 0 10px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.6)
  );
  backdrop-filter: blur(5px);

  &--normal {
    height: 80px; // Замість min-height
    .header__logo {
      width: 60px;
      height: 60px;
      transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    }
    .header__wrap {
      width: 60px;
      height: 80px;
      transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    }
  }

  &--short {
    height: 55px; // Теж height, замість min-height
    .header__logo {
      width: 45px;
      height: 45px;
      opacity: 1;
      transition: width 0.5s ease-in-out, height 0.5s ease-in-out,
        opacity 0.3s ease-in-out;
    }
    .header__wrap {
      width: 45px;
      height: 55px;
      opacity: 1;
      transition: width 0.5s ease-in-out, height 0.5s ease-in-out,
        opacity 0.3s ease-in-out;
    }
  }

  &--hiden {
    height: 0; // Використовуємо тільки height для приховування
    overflow: hidden;
    transition: height 0.5s ease-in-out;

    .header__logo,
    .header__wrap,
    .ParticleCanvas {
      opacity: 0;
      transition: opacity 0.3s ease-in-out width 0.3s ease-in-out;
    }
  }

  &--hiden-exit {
    transition: height 0.5s ease-in-out;
    overflow: hidden;

    .header__logo,
    .header__wrap,
    .ParticleCanvas {
      opacity: 1;
      transition: opacity 0.3s ease-in-out width 0.3s ease-in-out;
    }
  }

  &__subHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    pointer-events: none; // КЛІКИ ЗАБОРОНЕНО
    > * {
      pointer-events: auto; // КЛІКИ ДОЗВОЛЕНО
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 5px;
  }

  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
  }

  &__img {
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 6px black;
      background-color: rgb(white, 0.2);
    }
    &:active {
      box-shadow: 0 0 10px black;
    }
  }

  &__flag {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 20px;
    overflow: hidden;
  }

  &__flag-img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    filter: invert(1);
    transition: transform 0.5s ease-in-out;
  }

  &__flag-img1 {
    height: 25px;
    margin-right: 5px;
  }

  &__menu-flag {
    position: relative;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 7px;
    border: 1px solid transparent;
    &:not(.header__btn2):hover .header__menu-span {
      opacity: 1;
      transform: scale(1);
      box-shadow: 0 0 3px $theme-color;
    }

    .header__menu-span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border: 2px solid $theme-color;
      border-radius: 7px;
      transform: scale(0);
      transition: transform 0.3s, opacity 0.3s;
      opacity: 0;
    }
  }

  &__menu {
    position: absolute;
    top: 55px;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 160px;
    padding: 0 10px 10px 10px;
    border-bottom-left-radius: 10px;
    // box-shadow: inset 0 1px 3px black;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    z-index: 1;
    gap: 10px;
    transition: top 0.5s ease-in-out;
    animation: expHeight 0.2s ease-in-out forwards;
    &--large {
      gap: 5px;
      animation: expHeightLarge 0.2s ease-in-out forwards;
    }

    @keyframes expHeight {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 80px;
        opacity: 1;
      }
    }

    @keyframes expHeightLarge {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 80px;
        opacity: 1;
      }
    }
  }

  &__logo {
    width: 60px;
    transition: width 0.5s ease-in-out;
    // background-color: $theme-color3;
    // border-radius: 50%;
    cursor: pointer;
    &--transparent {
      background: transparent;
      @include mixin.button-shake;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    @include mixin.button;
  }

  &__btn2 {
    text-align: center;
    cursor: pointer;
    // width: 100%;
    height: 29px;
    // padding: 5px 10px;
    background-color: $theme-color;
    color: white;
    border-radius: 7px;
    box-shadow: 2px 2px 3px black;
    &:hover {
      background-color: darken($theme-color, 5%);
    }
  }
  &__btn-bounce {
    @include mixin.button-bounce;
    margin-right: 15px;
  }
}

.track {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  pointer-events: none;
  user-select: none;

  // Стан для з'явлення
  &.show {
    opacity: 1;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  // Стан для зникнення
  &.track--hiding {
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &__artist,
  &__song,
  &__gif {
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s linear;
  }

  &__artist {
    transform: translate(-300px, 0); // Початкова позиція зліва
  }
  &__song {
    transform: translate(300px, 0); // Початкова позиція справа
  }
  &__gif {
    transform: translate(0, -200px); // Початкова позиція на 200 пікселів вище
  }

  // Анімація з'явлення
  &.track--animated &__artist,
  &.track--animated &__song,
  &.track--animated &__gif {
    opacity: 1;
    transform: translate(0, 0); // Анімація в центр
  }

  // Анімація зникнення
  &.track--hiding &__artist {
    transform: translate(300px, 0); // Рух далі вправо
    opacity: 0;
  }
  &.track--hiding &__song {
    transform: translate(-300px, 0); // Рух далі вліво
    opacity: 0;
  }
  &.track--hiding &__gif {
    opacity: 0; // Плавне зникнення GIF
  }

  &__text {
    position: relative;
    font-size: 20px;
    color: white;
    width: fit-content;
    letter-spacing: 1px;
    font-weight: bold;
    text-align: center;
  }

  &__gif {
    height: auto;
    width: 100%;
    max-height: 150px;
    max-width: 300px;
    object-fit: contain;
    object-position: center;
  }

  &__artist {
    font-weight: bold;
    font-size: 25px;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    @media (max-width: 767px) {
      font-size: 15px;
    }
  }

  &__song {
    font-size: 17px;
    // font-style: italic;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  &__artist,
  &__song {
    max-width: 300px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
    &::before,
    &::after {
      content: "";
      width: 30%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }
    &::before {
      left: -30%;
      background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
    }
    &::after {
      left: 100%;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
    }
  }

  &__divider {
    width: 100%;
    height: 2px;
    background-image: linear-gradient(
      to right,
      transparent,
      white,
      transparent
    );
  }
}

.scrollBtn {
  @include mixin.fixedButton();
  position: fixed;
  right: 15px; // js controlled
  bottom: 15px;

  display: flex;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
  @media (max-width: 600px) {
    display: none;
  }
  &.hidden {
    opacity: 0;
  }
  &.visible {
    opacity: 1;
  }

  &__arrow {
    position: absolute;
    width: 25px;
    height: 25px;
    border-left: none;
    border-top: none;
    border-right: 2px #fff solid;
    border-bottom: 2px #fff solid;
    transform: rotate(-135deg);
    transition: transform 1s ease;
  }
}

.menu-enter {
  opacity: 0;
  transform: scaleY(0);
}
.menu-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.menu-exit {
  opacity: 1;
  transform: scaleY(1);
}
.menu-exit-active {
  opacity: 0;
  transform: scaleY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

// .headerNavBar {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   gap: 15px;
//   user-select: none;
//   font-size: 30px;
//   // text-shadow: 2px 2px 4px rgba($theme-color3, 0.5);
//   @media (max-width: 850px) {
//     font-size: 20px;
//     gap: 5px;
//   }
//   @media (max-width: 600px) {
//     font-size: 15px;
//   }

//   &__title {
//     display: flex;
//     justify-content: center;
//     text-align: center;
//     align-items: center;
//     color: white;
//     font-family: "Oswald" serif;
//     letter-spacing: 1px;
//     font-weight: bold;
//     text-transform: uppercase;
//     @media (max-width: 600px) {
//       max-width: 150px;
//     }
//   }

//   &__mirroredArrow {
//     display: inline-block;
//     transform: scaleX(-1);
//   }

//   &__btn {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     min-width: 35px;
//     min-height: 35px;
//     border-radius: 50%;
//     background: transparent;
//     border: 3px solid white;
//     color: white;
//     font-size: 20px;
//     cursor: pointer;
//     &:hover {
//       border: 3px solid $theme-color3;
//       color: $theme-color3;
//     }
//     @media (max-width: 600px) {
//       display: none;
//     }
//   }
// }
